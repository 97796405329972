import React from "react";
import GlobalStyle from "../components/common/global";

const NotFoundPage = () => (
  <>
    <GlobalStyle />
    <h1>PAGE NOT FOUND</h1>
  </>
);

export default NotFoundPage;
